<template>
  <Header title="Master Tagihan" link1="Master Tagihan"></Header>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="d-inline">
                <label for="">Tahun Ajar:</label>
                <select @change="loadData()" v-model="param.school_year_id" class="d-inline text-primary form-select form-select-noborder">
                  <option
                    v-for="sy in school_years"
                    :key="sy.school_year_id"
                    :label="sy.name"
                    :value="sy.school_year_id">
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="float-end">
                <div class="d-inline">
                  <button class="btn btn-circle btn-gradient mr-1" data-bs-toggle="modal" data-bs-target="#billModal" @click="add()"> <ion-icon name="add-circle-outline"></ion-icon> Tambah Tagihan</button>
                  <button v-if="!useImportVA" class="btn btn-circle btn-gradient mr-1" data-bs-toggle="modal" data-bs-target="#generateModal"> <ion-icon name="color-wand-outline"></ion-icon> Generate</button>

                  <router-link tag="a" to="/bill/type" class="btn btn-circle btn-warning mr-1">
                    <ion-icon name="document-text-outline"></ion-icon> Tipe Tagihan
                  </router-link>
                  <router-link tag="a" to="/bill_detail/type" class="btn btn-circle btn-warning mr-1">
                    <ion-icon name="document-text-outline"></ion-icon> Tipe Rincian
                  </router-link>
                  <router-link tag="a" to="/convenience/fee" class="btn btn-circle btn-info">
                    <ion-icon name="server-outline"></ion-icon> Biaya Admin
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2" v-if="sppPromoAnnuallyPaymentPercentage > 0">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="d-inline float-end">
                <label class="fw-bold">Potongan harga {{sppPromoAnnuallyPaymentPercentage}}% untuk pembayaran 1 tahun aktif</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-borderless table-data fw-bold">
            <thead>
              <tr>
                <th>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="">
                  </div>
                </th>
                <th>No</th>
                <th>Nama Tagihan</th>
                <th>Total Biaya</th>
                <th>Bulan</th>
                <th>Batas Tanggal</th>
                <th>Jumlah</th>
                <th>Opsi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bill, index) in bills" :key="index">
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="">
                  </div>
                </td>
                <td>{{ index + 1}}</td>
                <td>{{ bill.name }}</td>
                <td class="text-primary"> {{ bill.default_total_amount }} </td>
                <td>
                  <div v-if="bill.months.length < 12">
                    <span class="label label-success fw-bold" v-for="(bill) in bill.months" :key="bill">{{ getMonthByID(bill) }}</span>
                  </div>
                  <div v-else>
                    <span class="label label-success fw-bold">SEMUA</span>
                  </div>
                </td>
                <td>{{ bill.deadline_date }}</td>
                <td>{{ bill.total_bill_users }}</td>
                <td>
                  <a class="btn btn-sm btn-link-gray" href="#" data-bs-toggle="modal" data-bs-target="#billModal" @click="edit(bill.id)">Edit <ion-icon name="create-outline"></ion-icon></a>
                  <!-- <a class="btn btn-sm btn-link-gray" href="#"  data-bs-toggle="modal" data-bs-target="#generateModal">Generate <ion-icon name="color-wand-outline"></ion-icon></a> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div id="billModal" class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Tambah Tagihan</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="row g-3">
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Tahun Ajar</label>
              <select class="form-select" aria-label="Pilih Tahun Ajar" v-model="data.school_year_id">
                <option
                  v-for="sy in school_years"
                  :key="sy.school_year_id"
                  :label="sy.name"
                  :value="sy.school_year_id">
                </option>
              </select>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Tipe Tagihan</label>
              <select class="form-select" aria-label="Pilih Tipe Tagihan" v-model="data.spp_bill_type_id">
                <option
                  v-for="types in bill_types"
                  :key="types.id"
                  :label="types.name"
                  :value="types.id">
                </option>
              </select>
            </div>
            <div class="col-md-12 mb-3" v-if="!useCustomCodeBill">
              <label for="" class="form-label">Nama Tagihan</label>
              <input type="text" class="form-control" v-model="data.name">
            </div>
            <div class="col-md-10 mb-3" v-if="useCustomCodeBill">
              <label for="" class="form-label">Nama Tagihan</label>
              <input type="text" class="form-control" v-model="data.name">
            </div>
            <div class="col-md-2 mb-3" v-if="useCustomCodeBill">
              <label for="" class="form-label">Kode</label>
              <input type="number" class="form-control" v-model="data.options.code">
            </div>
            <div class="col-md-12 mb-3" v-if="!useImportVA">
              <label for="" class="form-label">Bulan Pembayaran</label>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="selectAllMonths" v-model="allSelectedMonth" @click="checkAllMonth()">
                    <label class="form-check-label" for="selectAllMonths">
                      Pilih Semua
                    </label>
                  </div>
                </div>
              </div>
              <div class="row row-cols-6">
                <div class="col-md-2" v-for="(m, key) in months" :key="key">
                  <div class="form-check">
                    <input class="form-check-input" v-model="data.months" :id="`m-`+key" type="checkbox" :value="key">
                    <label class="form-check-label" :for="`m-`+key">
                      {{m.id}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Detail Biaya</label>
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Nama</th>
                    <th v-if="!useImportVA">Biaya</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(detail, i) in data.details" :key="i">
                    <td> <button type="button" class="btn text-secondary btn-icon" @click="removeDetail(i)" v-if="detail.id == ''"><ion-icon name="trash-outline"></ion-icon></button></td>
                      <input type="hidden" class="form-control" v-model="detail.id">
                    <td>
                      <select class="form-select" v-model="detail.operation" @change="calculatePrice()">
                        <option
                          v-for="operation in math_operations"
                          :key="operation.id"
                          :label="operation.id"
                          :value="operation.symbol">
                        </option>
                      </select>
                    </td>
                    <td>
                      <select class="form-select" v-model="detail.spp_bill_detail_type_id" @change="setBillDetailType($event.target.value, i)">
                        <option
                          v-for="tipe in bill_detail_types"
                          :key="tipe.id"
                          :label="tipe.name"
                          :value="tipe.id">
                        </option>
                      </select>
                    </td>
                    <!-- <td>
                      <input type="text" class="form-control" v-model="detail.name">
                    </td> -->
                    <td v-if="!useImportVA">
                      <CurrencyInput v-model="detail.default_amount" :options="config" @change="calculatePrice()" />
                      <!-- <input type="number" class="form-control text-end" v-bind:class="{ 'text-success': detail.operation == '+', 'text-danger': detail.operation == '-' }" v-model="detail.default_amount" @change="calculatePrice()"> -->
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="3" class="text-end"><button type="button" class="btn btn-primary" @click="addDetail()"> <ion-icon name="create"> </ion-icon> Tambah Biaya </button></th>
                    <th v-if="!useImportVA"></th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="col-md-12 mb-3" v-if="!useImportVA">
              <label for="" class="form-label">Total Biaya</label>
              <CurrencyInputDisable v-model="data.default_total_amount" :options="config" />
              <!-- <input type="number" class="form-control text-success text-end" v-model="data.default_total_amount" readonly> -->
            </div>
            <div class="col-md-12 mb-3" v-if="!useImportVA">
              <label for="" class="form-label">Denda</label>
              <div class="row">
                <div class="col-md-3">
                  <select class="form-select" v-model="data.fine_rules.type">
                    <option value="percentage">Persentase</option>
                    <option value="nominal">Nominal</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <span v-if="data.fine_rules.type == 'nominal'" class="input-group-text">Rp</span>
                    <input type="number" class="form-control text-success text-end" v-model="data.fine_rules.value">
                    <span v-if="data.fine_rules.type == 'percentage'" class="input-group-text">%</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <select class="form-select" v-model="data.fine_rules.formula">
                    <option value="flat">Flat</option>
                    <option value="rollover">RollOver</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-3" v-if="UseLateMonthFine">
              <label for="" class="form-label">Denda Lewat Bulan</label>
              <div class="row">
                <div class="col-md-3">
                  <select class="form-select" v-model="data.fine_rules.late_month_fine.type">
                    <option value="percentage">Persentase</option>
                    <option value="nominal">Nominal</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <div class="input-group mb-3">
                    <span v-if="data.fine_rules.late_month_fine.type == 'nominal'" class="input-group-text">Rp</span>
                    <input type="number" class="form-control text-success text-end" v-model="data.fine_rules.late_month_fine.value">
                    <span v-if="data.fine_rules.late_month_fine.type == 'percentage'" class="input-group-text">%</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <select class="form-select" v-model="data.fine_rules.late_month_fine.formula">
                    <option value="flat">Flat</option>
                    <option value="rollover">RollOver</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label for="" class="form-label">Keterangan</label>
              <textarea class="form-control text-secondary" rows="2" v-model="data.description"></textarea>
            </div>
            <div class="col-md-6 mb-3">
              <label for="" class="form-label">Tanggal Pengingat</label>
              <input type="number" class="form-control" max="31" min="1" v-model="data.due_date">
            </div>
            <div class="col-md-6 mb-3">
              <label for="" class="form-label">Batas Tanggal</label>
              <input type="number" class="form-control" max="31" min="1" v-model="data.deadline_date">
            </div>
            <div class="col-md-12" v-if="!useImportVA">
              <label for="" class="form-label">Tipe Kelas</label>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-check">
                    <input class="form-check-input" id="classes" v-model="classType" :value="1" type="radio" name="classType" checked>
                    <label class="form-check-label" for="classes">Kelas Utama</label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" id="crossClasses" v-model="classType" :value="2" type="radio" name="classType">
                    <label class="form-check-label" for="crossClasses">Lintas Kelas</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-3" v-if="!useImportVA">
              <label for="" class="form-label">Kelas</label>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-check">
                    <input class="form-check-input" id="selectAllClasses" type="checkbox" value="" checked @click="checkAllClass('default')" v-model="allSelectedClass" v-show="classType == 1">
                    <label class="form-check-label" for="selectAllClasses" v-show="classType == 1">
                      Pilih Semua
                    </label>
                    <input class="form-check-input" id="selectAllCrossClasses" type="checkbox" value="" checked @click="checkAllClass('cross')" v-model="allSelectedClassCross" v-show="classType == 2">
                    <label class="form-check-label" for="selectAllCrossClasses" v-show="classType == 2">
                      Pilih Semua
                    </label>
                  </div>
                </div>
              </div>
              <div class="row row-cols-6" v-show="classType == 1">
                <div class="col-md-2" v-for="c in classes" :key="c.class_id">
                  <div class="form-check">
                    <input class="form-check-input" v-model="data.classes" :id="`c-`+c.class_id" type="checkbox" :value="c.class_id">
                    <label class="form-check-label" :for="`c-`+c.class_id">
                      {{c.name}}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row row-cols-6" v-show="classType == 2">
                <div class="col-md-2" v-for="c in crossClasses" :key="c.class_id">
                  <div class="form-check">
                    <input class="form-check-input" v-model="data.crossClasses" :id="`cc-`+c.class_id" type="checkbox" :value="c.class_id">
                    <label class="form-check-label" :for="`cc-`+c.class_id">
                      {{c.name}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <small>Terakhir diubah oleh <b>{{ data.user_updated.name }}</b> pada <b>{{ data.updated_at }}</b></small><br>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"> <ion-icon name="close-outline"></ion-icon> Tutup</button>
          <button v-bind:class="{'disabled': loadingSave }" type="button" class="btn btn-primary" @click="save()"> <i v-if="loadingSave" class="fa fa-spinner fa-pulse"></i><ion-icon v-if="!loadingSave" name="save-outline"></ion-icon> Simpan</button>
          <button v-bind:class="{'disabled': loadingGenerate }" v-if="data.id != '' && !useImportVA" type="button" class="btn btn-success" @click="generate()"> <i v-if="loadingGenerate" class="fa fa-spinner fa-pulse"></i><ion-icon v-if="!loadingGenerate" name="color-wand-outline"></ion-icon> Generate</button>
        </div>
      </div>
    </div>
  </div>

  <div id="generateModal" class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Generate Tagihan ke Siswa</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="row g-3">
            <div class="col-md-8 mb-3">
              <label for="" class="form-label">Pilih Tagihan</label>
              <div class="input-group mb-3">
                <select v-model="spp_bill_id" class="text-primary form-select">
                  <option value="">Semua Tagihan</option>
                  <option
                    v-for="b in bills"
                    :key="b.id"
                    :label="b.name"
                    :value="b.id">
                  </option>
                </select>
                <!-- <button class="btn btn-outline-secondary"> <ion-icon name="eye"></ion-icon> Detail </button> -->
              </div>
            </div>
            <div class="col-12 mb-3">
              <label for="student_name" class="form-label">Cari Siswa</label>
              <input id="student_name" name="student_name" type="text" class="form-select text-primary" placeholder="Nama siswa / ortu" v-model="searchField" @input="getSuggestions">
            </div>
            <div class="col-12 align-middle" v-if="Object.keys(selectedStudents).length > 0">
              <label for="" class="form-label">Siswa Terpilih: {{ Object.keys(selectedStudents).length }}</label>
              <div class="h-gap">
                <button type="button" v-for="(student, i) in selectedStudents" class="btn btn-outline-secondary" @click="removeSelectedStudent(i)">{{ student.child_name }} <ion-icon name="close-circle-outline"></ion-icon></button>
              </div>
              <!-- <span v-for="(student, i) in selectedStudents" class="badge bg-secondary">{{ student.child_name }} <ion-icon name="remove"></ion-icon> </span> -->
              <!-- <button type="button" class="btn btn-primary"> <ion-icon name="search-outline"></ion-icon> Cari</button> -->
              <!-- <div>Terseleksi <strong>{{ students.length }}</strong> Siswa dari <strong>1</strong> Kelas</div> -->
            </div>
            <div class="col-md-12 p-3 text-end">
              <button type="button" class="btn btn-primary" @click="generateByStudentIDs()"> <ion-icon name="color-wand-outline"></ion-icon> Generate</button>
            </div>
            <div class="col-md-12">
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <th>
                      <!-- <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" checked>
                      </div> -->
                    </th>
                    <th>Nama Siswa</th>
                    <th>Nama Ortu</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(student, i) in students" :key="i">
                    <td>
                      <span v-if="!selectedStudents[student.child_user_id]">
                        <button type="button" class="btn btn-sm btn-secondary" @click="addToSelectedStudent(student)"> <ion-icon name="add-outline"></ion-icon></button>
                      </span>
                      <!-- <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" checked>
                      </div> -->
                    </td>
                    <td>{{ student.child_name }}</td>
                    <td>{{ student.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"> <ion-icon name="close-outline"></ion-icon> Tutup</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script language="ts" src="./scripts/bill.ts"></script>
